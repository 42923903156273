import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { CommentModal, Menu, Modal, RecentlyOrdered, useIsAdmin, useIsMobile } from 'components';
import AddProductToList from 'components/layout/AddProductToList';
import PricesCounter from 'components/layout/PricesCounter';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';

import { refreshListView, selectProduct } from '../../actions';
import ProductCommentForm from '../ProductCommentForm';
import SetQuantity from '../SetQuantity';

import style from './ListViewItem.module.scss';

const ListViewItem = ({ item, uid, index, recentlyOrdered, children }) => {
  const { id, producer, name, qty, unit_name, thumbnail, image, ref_price, comment, prices_count } = item || {};

  const [isModalAddToListOpen, setIsModalAddToListOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const container = useRef(null);
  const isMobile = useIsMobile(767);
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();

  const clearTabindexEffectHandler = () => {
    if (container.current?.hasAttribute('tabindex')) {
      container.current?.removeAttribute('tabIndex');
    }
  };

  const deleteProductHandler = async () => {
    try {
      await ListsApi.deleteProduct(uid, [id]);
      notifyCommon(['Produkt został usunięty.']);
      dispatch(refreshListView());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const actions = [
    {
      title: `${comment ? 'Edytuj' : 'Dodaj'} komentarz`,
      action: () => setIsModalOpen(true),
      roles: ['admin', 'user'],
      icon: 'message'
    },
    {
      title: 'Dodaj do listy',
      icon: 'add',
      action: () => setIsModalAddToListOpen(true)
    },
    {
      title: 'Usuń z listy',
      action: deleteProductHandler,
      icon: 'trash',
      color: 'red'
    }
  ];

  useEffect(() => {
    dispatch(selectProduct(id));
  }, []);

  return (
    <div
      className={style.container}
      id={`list-product-${id}`}
      onClick={clearTabindexEffectHandler}
      ref={container}
    >
      <div className={style.checkbox}>{children || <p>{index + 1}.</p>}</div>
      <div className={style.wrapper}>
        <ProductThumbnailImage
          thumbnail={thumbnail}
          image={image}
          title={name}
          id={id}
        />
        <p className={style.text}>{name}</p>
        <PricesCounter count={prices_count} />
        <RecentlyOrdered data={recentlyOrdered} />
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Producent: </p>}
        <p className={style.text}>{producer}</p>
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Komentarz: </p>}
        <CommentModal
          comment={comment}
          modalTitle={'Komentarz do produktu ' + name}
        />
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Ilość: </p>}
        <SetQuantity
          uid={uid}
          id={id}
          qty={qty}
          unitName={unit_name}
        />
      </div>
      <div className={classNames(style.wrapper, style.menuWrapper)}>
        <Menu
          actions={actions}
          className={style.menu}
        />
      </div>
      <Modal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`Dodaj komentarz do produktu ${name}`}
      >
        <ProductCommentForm
          product={item}
          uid={uid}
          closeModal={() => setIsModalOpen(false)}
        />
      </Modal>
      <AddProductToList
        title={`Dodaj "${name}" do listy`}
        visible={isModalAddToListOpen}
        onClose={() => setIsModalAddToListOpen(false)}
        productId={id}
      />
    </div>
  );
};

export default ListViewItem;
